.flip-card {
    perspective: 1000px;
    /* Remove this if you don't want the 3D effect */
}

/* This container is needed to position the front and back side */

.flip-card-inner {
    transition: transform 0.8s;
    transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */

.flip-card-active .flip-card-inner {
    transform: rotateY(180deg);
}

/* Position the front and back side */

.flip-card-front, .flip-card-back {
    -webkit-backface-visibility: hidden;
    /* Safari */
    backface-visibility: hidden;
}

/* Style the back side */

.flip-card-back {
    transform: rotateY(180deg);
}