
.Toastify__toast-container {
    position : absolute;
    padding-top: 3em;
    width: 100%;
}

.Toastify__toast {
    min-height: 5px;
    height: 1.5em;
    min-width: 10em;
    margin-bottom: 0.25rem;
    padding:2px;
    padding-right:1em;
    background-color: #87cefa26;
    border-radius: 0.25em;
}

.Toastify__toast-body {
    color: black;
    text-shadow: 1px 1px 1px white; 
}
