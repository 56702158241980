.Letters {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.LettersContainer {
    display: flex;
    flex-flow: wrap;
    grid-gap: 0px;
    padding-right: 10px;
    /* max-width: 200px; */
    /* grid-template-columns: repeat(10, 1fr); */
    /* grid-template-rows: repeat(1, 1fr); */
    /* grid-template-areas: 'l1 l2 l3 l4 l5 l6 l7 l8 l9 l10'; */
    list-style: none;
}

.DraggingOver {
    background: darken(pink, 2%);
}
/* 
@include breakpoint($midMobile) {
    .Letters {
        grid-column: 1 / -1;
    }
}

@include breakpoint($largeMobile) {
    .LettersContainer {
        display: flex;
        justify-content: center;
        width: 100%;
        max-width: 530px;
        padding: 2% 0;
        border-radius: 3px;
        transition: 0.1s background;
    }
}

@include breakpoint($desktop) {
    .LettersContainer {
        max-width: 630px;
        padding: 10% 0;
    }
} */