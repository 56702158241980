.Game {
    display: grid;
    grid-template-rows: repeat(3, 0.6fr) 4fr;
    grid-gap: 10px;
    max-width: 500px;
    min-height: 100px; 
    padding: 2% 15px 5%;
    margin: 0 auto;
    text-align: center;
}

.Empty {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #eedaa3;
    font-weight: 500;
}

.Loader,
.Loader:after {
    border-radius: 50%;
    width: 60px;
    height: 60px;
}

.Loader {
    margin: 0 auto;
    font-size: 5px;
    position: relative;
    text-indent: -9999px;
    border-width: 5px;
    border-style: solid;
    border-color:
            rgba(#ffecb7, 0.2)
            rgba(#ffecb7, 0.2)
            rgba(#ffecb7, 0.2)
            #ffecb7;
    transform: translateZ(0);
    animation: load 1.1s infinite linear;
}

@keyframes load {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}


@include breakpoint(midMobile) {
    .Game {
        grid-template-columns: auto 50px;
        grid-template-rows: 40px 60px auto;
    }
}

@include breakpoint(largeMobile) {
    .Game {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(3, auto);
        max-width: 700px;
    }
}

@include breakpoint(desktop) {
    .Game {
        min-height: 100px;
    }
}
