inputKey {
    width: 100%;
    height: 100px;
    padding: 20px;
    font-size: 20px;
    border: none;
    box-sizing: border-box;
}

.simple-keyboard {
    max-width: 400px;
}

.simple-keyboard.hg-theme-default.myTheme {
    border: 5px solid rgb(255, 0, 0, 0.7);
    border-radius: 10px;
    margin: 10px;
    width: calc(100% - 20px);
}

.simple-keyboard.hg-layout-default .hg-button.hg-red {
    background: rgb(255, 0, 0, 0.7);
    color: white;
}

.simple-keyboard.hg-layout-default .hg-button.hg-blue {
    background: rgba(0, 181, 204, 1);
    color: white;
}

.simple-keyboard.hg-layout-default .hg-button.hg-highlight {
    box-shadow: 0px 0px 40px 5px rgba(255, 255, 0, 0.5);
    z-index: 1;
}