

.Submit {
    display: flex;
    justify-content: center;
    align-items: center;
}

.Button {

    margin: 0 5px;
}

.SubmitButton {
    @include button-filled;
}

.CancelButton {
    @include button-outlined;
}

@include breakpoint(midMobile) {
    .Submit {
        grid-column: 1 / -1;
    }
}

@include breakpoint(desktop) {
    .Button {
        @include button-regular-width;
        @include button-hover;
    }
}
